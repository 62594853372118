import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/SU_x6CUnQ1Q">
    <SEO title="Psalm 23 - Greatest Hits" />
  </Layout>
)

export default SermonPost
